var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": ""
    }
  }, [_vm.myGudang ? _c('header', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('h5', [_c('strong', [_vm._v("Lokasi Gudang: " + _vm._s(this.myGudang.data.nama_gudang))])])])], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', [_c('label', [_c('strong', [_vm._v("Pilih lokasi 2 "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("(*)")])])]), _c('v-select', {
    attrs: {
      "options": _vm.optbloks,
      "label": "text"
    },
    on: {
      "input": function ($event) {
        return _vm.getData();
      }
    },
    model: {
      value: _vm.blokasal,
      callback: function ($$v) {
        _vm.blokasal = $$v;
      },
      expression: "blokasal"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal")])]), _c('p', [_vm._v(_vm._s(_vm.so.tanggal))])]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("No")])]), _c('p', [_vm._v(_vm._s(_vm.so.no))])]), _c('b-col', {
    attrs: {
      "md": "5",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Keterangan")])]), _c('p', [_vm._v(_vm._s(_vm.so.keterangan ? _vm.so.keterangan : "-"))])]), _vm.so.status == 0 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-danger",
      "size": "sm"
    }
  }, [_vm._v("Belum Selesai")])], 1) : _vm._e(), _vm.so.status == 1 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-success",
      "size": "sm"
    }
  }, [_vm._v("Selesai")])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingpenyimpanan
    }
  }, [_c('b-card', {
    staticStyle: {
      "overflow-y": "auto",
      "max-height": "500px"
    }
  }, [_c('b-table', {
    attrs: {
      "sticky-header": "400px",
      "small": "",
      "bordered": "",
      "striped": "",
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.penyimpanan_id
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(stok)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.stok) + " ")];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : "-") + " ")]), _vm._v(" "), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$t('Room')) + " : ")]), _c('small', [_vm._v(_vm._s(item.blok ? item.blok.blok : "-"))]), _c('small', [_vm._v(_vm._s(item.palet ? item.palet.palet : "-"))]), _c('small', [_vm._v(_vm._s(item.rak ? item.rak.rak : "-"))]), _c('small', [_vm._v(_vm._s(item.laci ? item.laci.laci : "-"))])];
      }
    }, {
      key: "cell(qtyso)",
      fn: function (_ref4) {
        var item = _ref4.item,
          index = _ref4.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.qtysoComputed,
            callback: function ($$v) {
              _vm.$set(item, "qtysoComputed", $$v);
            },
            expression: "item.qtysoComputed"
          }
        })];
      }
    }, {
      key: "cell(so)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.id_stok_opname) + " ")];
      }
    }, {
      key: "cell(selisih)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.qtysoComputed - item.stok) + " "), _c('p', [item.stok < item.qtysoComputed ? _c('small', {
          staticClass: "text-info"
        }, [_c('i', [_vm._v("(Stok Hasil SO lebih)")])]) : _vm._e(), item.stok == item.qtysoComputed ? _c('small', {
          staticClass: "text-success"
        }, [_c('i', [_vm._v("(Stok sesuai)")])]) : _vm._e(), item.stok > item.qtysoComputed ? _c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v("(Stok Hasil SO kurang)")])]) : _vm._e()])];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('footer', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.so.status == 0 ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.save($event);
      }
    }
  }, [_vm._v("Simpan")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }