<template>
  <b-overlay :show="loading" rounded>
    <header v-if="myGudang">
      <b-row>
        <b-col sm="12" md="12">
          <h5>
            <strong>Lokasi Gudang: {{ this.myGudang.data.nama_gudang }}</strong>
          </h5>
        </b-col>
      </b-row>
    </header>
    <b-row>
      <b-col sm="12" md="12">
        <!-- Tujuan Gudang -->
        <b-form-group>
          <label><strong>Pilih lokasi 2 <span class="text-danger">(*)</span></strong></label>
          <v-select :options="optbloks" v-model="blokasal" label="text" @input="getData()" />
        </b-form-group>
        <!-- / -->
      </b-col>
      <b-col md="2" sm="12">
        <p><strong>Tanggal</strong></p>
        <p>{{ so.tanggal }}</p>
      </b-col>
      <b-col md="2" sm="12">
        <p><strong>No</strong></p>
        <p>{{ so.no }}</p>
      </b-col>
      <b-col md="5" sm="12">
        <p><strong>Keterangan</strong></p>
        <p>{{ so.keterangan ? so.keterangan : "-" }}</p>
      </b-col>
      <b-col md="3" sm="12" v-if="so.status == 0">
        <p><strong>Status</strong></p>
        <b-badge variant="light-danger" size="sm">Belum Selesai</b-badge>
      </b-col>
      <b-col md="3" sm="12" v-if="so.status == 1">
        <p><strong>Status</strong></p>
        <b-badge variant="light-success" size="sm">Selesai</b-badge>
      </b-col>

      <!-- Input Stock Opname -->
      <b-col sm="12" md="12">
        <b-overlay :show="loadingpenyimpanan">
          <b-card style="overflow-y: auto; max-height: 500px">
            <b-table sticky-header="400px" small bordered striped responsive :fields="fields" :items="penyimpanan_id">
              <template #cell(no)="row">
                <div class="text-center">
                  {{ row.index + 1 }}
                </div>
              </template>
              <template #cell(stok)="{ item }">
                {{ item.stok }}
                <!-- <b-form-input type="number" v-model="item.stok" /> -->
              </template>
              <template #cell(kode)="{ item }">
                {{ item.barang ? item.barang.kode : "-" }}<br>
                <small>{{ item.barang ? item.barang.no_batch : "-" }}</small>
              </template>
              <template #cell(nama)="{ item }">
                {{ item.barang ? item.barang.varian : "-" }}<br>
                <small>{{ item.barang.kategori.kategori }}</small> /
                <small>
                  {{ item.barang ? item.barang.nama : "-" }}
                </small> <br>
                <small>{{ $t('Room') }} : </small>
                <small>{{ item.blok ? item.blok.blok : "-" }}</small>
                <small>{{ item.palet ? item.palet.palet : "-" }}</small>
                <small>{{ item.rak ? item.rak.rak : "-" }}</small>
                <small>{{ item.laci ? item.laci.laci : "-" }}</small>

              </template>
              <template #cell(qtyso)="{ item, index }">
                <b-form-input v-model="item.qtysoComputed" type="number" />
              </template>
              <template #cell(so)="{ item }">
                {{ item.id_stok_opname }}
              </template>
              <template #cell(selisih)="{ item }">
                {{ item.qtysoComputed - item.stok }}
                <p>
                  <small class="text-info" v-if="item.stok < item.qtysoComputed"><i>(Stok Hasil SO lebih)</i></small>
                  <small class="text-success" v-if="item.stok == item.qtysoComputed"><i>(Stok sesuai)</i></small>
                  <small class="text-danger" v-if="item.stok > item.qtysoComputed"><i>(Stok Hasil SO kurang)</i></small>
                </p>
              </template>

              <!-- <template #cell(actions)="row">
              <b-link class="text-danger" @click.prevent="barangs.splice(row.index, 1)">
                <feather-icon size="24" svgClasses="text-success" icon="XCircleIcon" />
              </b-link>
            </template> -->
            </b-table>
          </b-card>
        </b-overlay>
      </b-col>
      <!-- / -->
    </b-row>
    <footer class="d-flex justify-content-end">
      <b-button v-if="so.status == 0" variant="primary" @click.prevent="save">Simpan</b-button>
      <!-- <b-button variant="info" @click.prevent="resetform">Reset</b-button> -->
    </footer>
  </b-overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BCard,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BTable,
  BFormInput,
  BLink,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BCard,
    BFormGroup,
    BFormSelect,
    BOverlay,
    vSelect,
    BTable,
    BFormInput,
    BLink,
    BBadge,
  },
  data() {
    return {
      loadingpenyimpanan: false,
      optbloks: [],
      blok: null,
      blokasal: null,
      loading: false,
      selectedBarang: null,
      barangs: [],
      penyimpanan_id: [],
      jumlah: 0,
      stok: 0,
      id_stok_opname: 0,
      status: 1,
      so: {
        tanggal: null,
        no: null,
        keterangan: null,
        status: null,
      },
      userData: JSON.parse(localStorage.getItem("userData")),
      fields: [
        {
          key: "no",
          label: "No",
        },
        {
          key: "kode",
          label: "Kode/SKU",
          sortable: true
        },
        {
          key: "nama",
          label: "Product",
          sortable: true
        },
        // {
        //   key: "blok.blok",
        //   label: "Blok",
        //   sortable: true
        // },
        // {
        //   key: "palet.palet",
        //   label: "palet",
        // },
        // {
        //   key: "rak.rak",
        //   label: "rak",
        // },
        // {
        //   key: "laci.laci",
        //   label: "laci",
        // },
        {
          key: "stok",
          label: "Stok",
        },
        {
          key: "barang.satuan.satuan",
          label: "satuan",
        },
        {
          key: "qtyso",
          label: "Hasil Stok Opname",
        },
        {
          key: "selisih",
          label: "selisih barang",
        }
      ],
    };
  },
  computed: {
    // Computed property untuk qtyso
    "item.qtysoComputed": {
      get() {
        // Mengembalikan nilai qtyso dan menetapkan 0 jika undefined
        return this.penyimpanan_id.map(item => item.qtyso === null || item.qtyso === undefined ? item.qtyso = 0 : item.qtyso);
      },
      set(value) {
        // Set nilai qtyso pada item terkait
        value.forEach((val, index) => {
          this.penyimpanan_id[index].qtyso = val;
        });
      }
    },
  },
  mounted() {
    this.getBloks()
    // this.getData()
    this.getDataSo()
    if (this.$route.params.id) {
      this.id_stok_opname = this.$route.params.id;
    }
  },
  methods: {
    resetform() {
      this.item.jumlah = 0;
      this.jumlah = 0;
    },
    async save() {
      if (this.id) {
        this.id = this.id
      }
      let payloads = []
      this.penyimpanan_id.map(item => {
        payloads.push({
          id_stok_opname: this.$route.params.id,
          id_penyimpanan: item.id,
          stok: item.jumlah,
          jumlah: item.jumlahso
        })
      })
      let payload1 = {
        status: this.status
      }
      if (this.$route.params.id) {
        payload1.id = this.$route.params.id;
      }
      try {
        this.loading = true
        await this.$store.dispatch('stokopname/saveSo', payloads)
        await this.$store.dispatch('stokopname/save', [payload1])
        this.loading = false

        this.displaySuccess({
          message: 'Stok Opname berhasil disimpan!'
        })
        this.getData();
        setTimeout(() => {
          this.loading = false;
          this.$router.push({ name: 'stock-opname-rincian', params: { id: this.$route.params.id } });
        }, 1000);
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    async getData() {
      const params = {}
      if (this.myGudang) params.gudang_id = this.myGudang.data.id
      if (this.myGudang) params.blok_id = this.blokasal.value
      // params.length = 10
      this.loadingpenyimpanan = true
      this.$store
        .dispatch("tokopenyimpanan/getData", params)
        .then((response) => {
          this.penyimpanan_id = response
          this.penyimpanan_id.map((item) => {
            item.jumlahso = item.jumlah
            item.stok = item.jumlah
          })
          this.penyimpanan_id.sort((a, b) => b.stok - a.stok);
          // this.penyimpanan_id.forEach(item => {
          //   if (item.qtyso === null || item.qtyso === undefined) {
          //     item.qtyso = 0;
          //   }
          // });
          this.loadingpenyimpanan = false
        })
        .catch((e) => {
          this.loadingpenyimpanan = false
          this.displayError(e);
          return false;
        });
      // const stocks = await this.$store.dispatch("tokopenyimpanan/getData", params)
      // stocks.map(stock => {
      //   stock.jumlah = 0
      // })
      // this.penyimpanan_id = JSON.parse(JSON.stringify(stocks))
      // this.totalRows = JSON.parse(JSON.stringify(stocks)).length
    },
    getDataSoRincian() {
      if (this.user.level.id == 0) {
        this.$store
          .dispatch("stokopname/getDataSo", { id_stok_opname: this.$route.params.id })
          .then(() => {
            this.items = this.$store.state.stokopname.datas;
            this.totalRows = this.items.length;
          })
          .catch((e) => {
            this.displayError(e);
            return false;
          });
      }
    },
    async getDataSo() {
      const so = await this.$store
        .dispatch("stokopname/getDataById", this.$route.params.id);
      this.so = so;
    },
    async getBloks(item) {
      const bloks = await this.$store.dispatch("tokoblok/getData", { gudang_id: item });
      bloks.map((gudang) => {
        // this.otherGudangs = response
        this.optbloks.push({
          text: gudang.blok,
          value: gudang.id,
        })
        // this.blok = this.optbloks

        // if (gudang.id != this.myGudang.id) {
        // this.otherGudangs.push({
        //   value: gudang.id,
        //   text: gudang.nama_gudang,
        // });
        // }
      });
    },
  },
  watch: {
    selectedBarang(barang) {
      if (barang) {
        this.barangs.push({
          id: barang.value,
          name: barang.text,
          jumlah: 0,
          isFinalStock: false,
        });
      }
    },
  },
};
</script>
